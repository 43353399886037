import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import OcNotification from '@/components/OcNotification/index.js'
import './directive/currency'
import './directive/datepicker'
import './assets/style/element-variables.scss'
import './assets/style/common.scss'
// import './assets/style/theme.css'
import locale from 'element-ui/lib/locale/lang/en'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard);
Vue.use(ElementUI,{locale})
Vue.use(OcNotification)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
