import Vue from 'vue';
import moment from 'moment';



function datetimePickUpdate(el,binding){
    const value = binding.value
    const date = moment(new Date()).format('YYYY-MM-DD')
    const [year, month, day] = date.split('-')
    const yearEle = el.getElementsByClassName('axis_year')[0]
    const monthEle = el.getElementsByClassName('axis_month')[0]
    const dayEle = el.getElementsByClassName('axis_day')[0]
    const yearList = yearEle.children[0].childNodes
    const monthList = monthEle.children[0].childNodes
    const dayList = dayEle.children[0].childNodes
    for (var i in yearList) {
        var y = yearList[i]
        if (y && y.nodeType == 1) {
            const yearText = Number(y.textContent)
            if (yearText > Number(year)) {
                y.style.opacity = '0'
            }
        }
    }
    for (var i in monthList) {
        var m = monthList[i]
        if (m && m.nodeType == 1) {
            const monthText = Number(m.textContent)
            if (monthText > Number(month)) {
                m.style.opacity = '0'
            }
        }
    }
    for (var i in dayList) {
        var d = dayList[i]
        if (d && d.nodeType == 1) {
            const dayText = Number(d.textContent)
            if (dayText > Number(day)) {
                d.style.opacity = '0'
            }
        }
    }
}
Vue.directive('datepicker', {
    inserted(el, binding) {
        datetimePickUpdate(el,binding)
    },
    update(el, binding) {
        datetimePickUpdate(el,binding)
    }
})