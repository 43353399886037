<template>
	<div class="ocnotification-container">
		<img src="@/assets/success.svg" alt="">
		<div>{{content}}</div>
	</div>
</template>

<script>
	export default {
		props: {
			content:"Successfully Submitted"
		}
	}
</script>

<style scoped lang="scss">
	.ocnotification-container {
		width: 206px;
		min-height: 144px;
		background: rgba(0, 0, 0, 0.7);
		border-radius: 24px;
		position: fixed;
		top: 50%;
		left: 50%;
		margin-top: -72px;
		margin-left: -103px;
		padding: 24px 17px;
		text-align: center;
		font-weight: 700;
		font-size: 14px;
		line-height: 22px;
		color: #FFFFFF;
		z-index: 9999;
		img {
			width: 40px;
			height: 40px;
			margin-bottom: 12px;
		}
		
	}
</style>