import OcNotification from "./index.vue";
export default {
    install(Vue){
        let messageBox = null; //保证只创建一个messageBox
        // 注册组件
        Vue.component('OcNotification',OcNotification);
        // 原型上添加方法
        Vue.prototype.$OcNotification= {
            show,
            hide,
            primary,
            success,
            warn,
            danger
        }
        function show(props,callback) {
          if(!messageBox){
              const MessageBox = Vue.extend({
                  render(h){
                      return h(OcNotification,{props})
                  }
              })
              messageBox = new MessageBox();
              this.vm = messageBox.$mount(); //挂载方法会返回一个MessageBox实例对象
              document.body.appendChild(this.vm.$el);
              callback && callback();
			  setTimeout(() =>{
			  	this.hide()
			  },5000)
          }
        }
        function hide() {
           document.body.removeChild(this.vm.$el);
           messageBox.$destroy();
           messageBox=null;
           this.vm = null;
        }
        function primary(props,callback){
            this.show({...props,type:'primary'},callback)
        }
        function success(props,callback){
            this.show({...props,type:'success'},callback)
        }
        function warn(props,callback){
            this.show({...props,type:'warn'},callback)
        }
        function danger(props,callback){
            this.show({...props,type:'danger'},callback)
        }
    }
};