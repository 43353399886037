import Vue from 'vue'
// import { currency } from '@/components/currency'  //后面改为从接口取，存为全局变量可用

import store from '@/store/index'

function updateNodeByCurrency(el, value) {
    const currency = store.state.currencyIcon || null
    if (value && currency) {
        if(el.children.length == 0){
            const fragment = document.createDocumentFragment()
            const img = document.createElement('img')
            const span = document.createElement('span')
            const spanTip = document.createElement('span')
            img.src = currency[value].icon
            img.style = "width:40px;height:40px";
            spanTip.textContent = currency[value].fullName
            span.textContent = value
            span.appendChild(spanTip)
            fragment.appendChild(img)
            fragment.appendChild(span)
            // fragment.appendChild(spanTip)
            el.appendChild(fragment)
        }else{
            var img = el.children[0]
            var span = el.children[1]
            const spanTip = span.lastChild
            img.src = currency[value].icon
            img.style = "width:40px;height:40px";
            spanTip.textContent = currency[value].fullName
            span.textContent  = value
            span.appendChild(spanTip)
            el.innerHTML = ''
            el.appendChild(img)
            el.appendChild(span)
        }
    }
}
Vue.directive('currency', {
    // bind(el, binding) {
    //     console.log('bind',el)
    //     updateNodeByCurrency(el, binding.value)
    // },
    inserted(el, binding) {
        //console.log('inserted', el,binding)
        updateNodeByCurrency(el, binding.value)
    },
    update(el,binding){
        if(binding.value == binding.oldValue){
            return
        }
        //console.log('update',el,binding)
        updateNodeByCurrency(el, binding.value)
    }
})