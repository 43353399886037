import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/giftCardList'
  },

  {
    path: '/giftLogin',
    name: 'giftLogin',
    meta: {
      title: 'giftLogin'
    },
    component: () => import('@/components/giftLogin.js')
  },
  {
    path: '/giftDetail',
    name: 'giftDetail',
    meta: {
      title: 'giftDetail'
    },
    component: () => import('@/components/gift-detail.js')
  },
  {
    path: '/orderPay',
    name: 'orderPay',
    meta: {
      title: 'orderPay'
    },
    component: () => import('@/components/order-pay.js')
  },
  {
    path: '/historicalOrders',
    name: 'historicalOrders',
    meta: {
      title: 'historicalOrders'
    },
    component: () => import('@/components/historical-orders.js')
  },

  {
    path: '/giftCardList',
    name: 'giftCardList',
    meta: {
      title: 'giftCardList'
    },
    component: () => import('@/components/giftCardList.js')
  },

  // 礼品卡移动端页面
  // {
  //   path:'/index',
  //   name:'index',
  //   meta:{
  //     title:''
  //   },
  //   component:()=> import('@/components/mobile/index.vue')
  // },


  // {
  //   path:'/query',
  //   name:'query',
  //   meta:{
  //     title:''
  //   },
  //   component:()=> import('@/components/mobile/query.vue')
  // },
  {
    path: '*',
    component: () => import('@/components/common/404.vue')
  }
]

const router = new VueRouter({
  routes
})

let GetRequest = function (fullURL) {
  var theRequest = new Object();
  let strs;
  if (fullURL.indexOf("?") != -1) {
    var str = fullURL.substr(fullURL.indexOf("?") + 1);
    strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
    }
  }
  return theRequest;
}

router.beforeEach((to, from, next) => {
  let query = from.query
  let url = GetRequest(window.location.href);
  if (to.path == '/giftLogin' && !url.partnerUserId) {
    next();
    return;
  }
  if (to.path == '/giftCardList' && url.partnerUserId) {
    sessionStorage.removeItem("isEmail");
    // next();
    // return;
  }
  if (to.path == '/giftLogin' && url.partnerUserId) {
    let p = Object.assign({}, to, { query: url })
    sessionStorage.setItem('showGiftCardList',true)
    next({path:'/giftCardList',query:p.query})
    return
  }
  if (to.path == '/giftCardList' && !url.partnerUserId) {
    let isEmail = sessionStorage.getItem("isEmail");
    if (url && !isEmail) {
      let p = Object.assign({}, to, { query: url })
      next({path:'/giftLogin',query:p.query})
      return
    }
  }

  if (!to.query.apiKey && from.name != to.name) {
    if (to.query) {
      query = Object.assign({}, query, to.query)
    }
    const p = Object.assign({}, to, { query })
    const href = router.resolve(p).href
    if (JSON.stringify(window.Telegram.WebApp.initDataUnsafe) !== '{}') {
      next()
    } else {
      location.href = href
    }
  } else {
    next()
  }
})
export default router
