import Vue from 'vue'
import Vuex from 'vuex'
import { convertConfig} from '@/api/service.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	currencyIcon:{},
    LOADING: false,
  	urlParam:{
  		apiKey:{
  			value:null,
  			required:true
  		},
  		partnerUserId:{
  			value:null,
  			required:true
  		},
  		timestamp:{
  			value:null,
  			required:true
  		},
  		signature:{
  			value:null,
  			required:true
  		},
  		tranType:{
  			value:null,
  			required:false
  		},
  		lang:{
  			value:null,
  			required:false
  		},
  		fiatCurrency:{
  			value:null,
  			required:false
  		},
  		fromCurrency:{
  			value:null,
  			required:false
  		},
  		toCurrency:{
  			value:null,
  			required:false
  		},
  		direction: {
  			value:null,
  			required:false
  		},
		tranType: {
			value:null,
			required:false
		},
		email: {
			value:null,
			required:false
		}
  	},
	
	orderData:{},
	sysConfig:null
  },
  getters: {
	  hideButton(state){
		return state.urlParam.tranType.value?false:true
	  },
	  orderData(state){
	  	return state.orderData
	  },
	  sysConfig(state){
		  return state.sysConfig
	  }
  },
  mutations: {
	  updateUrlParam(state,payLoad){
	  	  this.state.urlParam[payLoad.key].value = payLoad.value
	  },
	  SET_CURRENCY_ICON:(state,payload)=>{
          state.currencyIcon = payload
	  },
	  SET_CONVERTCONFIG:(state, payload) => {
	  	state.convertConfig = payload
	  },
	  hideLoading (state) {
	      state.LOADING = false
	  },
	  SET_ORDERDATA:(state, payload) => {
	  	state.orderData = payload
	  },
	  showLoading(state){
	     state.LOADING = true    
	  },
	  SET_SYSCONFIG(state,payload){
		  state.sysConfig = payload
	  }
  },
  actions: {
	  getConvertConfig({ commit, state }, payload){
	  	return new Promise((resolve, reject)=>{
	  		if(state.convertConfig){
	  			resolve(state.convertConfig)
	  			return
	  		}
	  		convertConfig().then(res=>{
	  			if(res.code == '0'){
	  				commit('SET_CONVERTCONFIG', res)
	  				resolve(res)
	  				
	  			}
	  		})
	  	})
	  	
	  },
  },
  modules: {
  }
})
