export function isMobile() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
	//console.log('userAgent',userAgent)
	if(JSON.stringify(window.Telegram.WebApp.initDataUnsafe)!=='{}'){
		userAgent = 'Telegram'
		window.userAgent='Telegram'
	}
    // 常见的移动浏览器和操作系统标识符
    const mobileRegex = /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Telegram/i;
    return mobileRegex.test(userAgent);
}